<template>
  <g :id="`FemaleAverageAvatarMobile_${uniqueIdSuffixA}`">
    <circle style="fill: #d0d0d0" cx="68.5" cy="272.8" r="38.2" />
    <g>
      <defs>
        <ellipse
          :id="`SVGID_12345_${uniqueIdSuffixA}`"
          cx="68.5"
          cy="272.8"
          rx="32"
          ry="33.1"
        />
      </defs>
      <clipPath :id="`SVGID_6789_${uniqueIdSuffixB}`">
        <use
          :xlink:href="`#SVGID_12345_${uniqueIdSuffixA}`"
          style="overflow: visible"
        />
      </clipPath>
      <path
        :style="`clip-path: url(#SVGID_6789_${uniqueIdSuffixB});  fill: #868686`"
        d="M88.5,291.6
			c-0.4-1.9-1.7-3.5-3.5-4.2l-5.9-2.3c0.7-1.3,1.3-2.9,1.7-4.5c-0.1,0.7-0.2,1.2-0.2,1.2s1.3-2.6,0.5-5.1c-0.1-0.6-0.1-1.3-0.3-1.9
			c-2.1-7.8,2.5-23.5-12-23c-14.5-0.5-9.9,15.2-12,23c-0.2,0.6-0.3,1.3-0.3,1.9c-0.8,2.4,0.4,5.1,0.4,5.1s-0.2-0.7-0.2-1.8
			c0.3,1.8,1,3.5,1.7,5l-6.2,2.9c-2.1,1-3.6,2.9-4,5.2l-0.6,9.9c-0.1,0.3-0.3,0.7-0.3,1c0,4,9.5,7.2,21.2,7.2s21.2-3.2,21.2-7.2
			c0-0.4-0.1-0.7-0.3-1L88.5,291.6z M78.4,283.3c-0.1,0.4-0.2,0.9-0.3,1.3l0,0C78.2,284.2,78.3,283.7,78.4,283.3z M59.4,284.6
			L59.4,284.6c-0.1-0.5-0.2-1-0.3-1.4C59.2,283.7,59.3,284.2,59.4,284.6z"
      />
    </g>
  </g>
</template>
<script>
export default {
  computed: {
    uniqueIdSuffixA() {
      return Math.floor(Math.random() * (25 - 2) + 2);
    },
    uniqueIdSuffixB() {
      return Math.floor(Math.random() * (50 - 26) + 62);
    },
  },
};
</script>
